<template>
  <div class="login flex flex-col justify-center h-screen min-h-608">
    <div class="flex justify-center">
      <router-link to="/">
        <img src="@/assets/quoteworx-logo.png" alt="Logo" />
      </router-link>
    </div>
    <p v-if="isStaging" class="text-center">Environment: staging</p>
    <LoginForm v-if="access == 'login'" @login="toDashboard" />
    <ForgotForm v-if="access == 'forgot-password'" />
    <LogoutForm v-if="access == 'logout'" :user="user" />
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import LoginForm from "@/components/LoginForm";
import LogoutForm from "@/components/LogoutForm";
import ForgotForm from "@/components/ForgotForm";

export default {
  components: {
    LoginForm,
    LogoutForm,
    ForgotForm,
  },
  props: ["user", "access"],
  setup(props) {
    const isStaging = process.env.VUE_APP_ENV == "staging";
    const router = useRouter();
    const toDashboard = () => {
      switch (props.user.claims.user_role) {
        case "estimator":
          router.push({ name: "QuotesList" });
          break;
        case "office":
        case "admin":
          router.push({ name: "Search" });
          break;
        default:
          router.push({ name: "Dashboard" });
      }
    };

    return { isStaging, toDashboard };
  },
};
</script>
