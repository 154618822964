import { ref } from 'vue'

export default function usePhone() {
  const error = ref(null)

  const formatPhone = (phone) => {
    if (!phone) return

    error.value = null

    try {
      phone = validatePhone(phone)
      if (phone.length !== 10) {
        throw 'Phone number should be 10 digits'
      }
      phone = '(' + phone.substr(0, 3) + ') ' + phone.substr(3, 3) + '-' + phone.substr(6)
    } catch (err) {
      console.error(err)
      error.value = err
    }
    return phone
  }

  const validatePhone = (phone) => {
    return phone.replace(/[^0-9]/g, "")
      .replace(/^1+/g, "");
  }

  return { error, formatPhone, validatePhone }
}
