import { ref } from 'vue'
import { auth } from '@/firebaseConfig'

export default function useLogin() {
  const error = ref(null)

  const login = async (email, password) => {
    error.value = null
    try {
      const res = await auth.signInWithEmailAndPassword(email, password)
      return res
    } catch (err) {
      console.error(err.message)
      error.value = "Incorrect login credentials"
    }
  }

  return { error, login }
}
