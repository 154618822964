<template>
  <div
    class="bg-hero px-10 pt-12 laptop:bg-center laptop:bg-cover laptop:bg-no-repeat laptop:flex laptop:px-20 desktop:px-40"
  >
    <div class="laptop:w-6/12">
      <div class="logo pb-5">
        <img
          class="m-auto laptop:w-auto laptop:ml-0"
          :src="require('@/assets/home/quote-logo.png')"
        />
      </div>
      <div
        class="text-center text-xl m-auto laptop:text-left laptop:w-full laptop:text-2xl"
      >
        #1 Way To Streamline Your Business
      </div>
      <div
        class="text-center font-bold text-3xl pt-2 pb-5 laptop:text-left laptop:text-4xl"
      >
        CLOSE MORE DEALS
      </div>
      <div
        class="text-sm w-80 m-auto text-center leading-relaxed laptop:text-left laptop:ml-0"
      >
        QuoteWorx streamlines the customer acquisition process and helps
        contractors visualize their workload. Customers today have become
        accustomed to letting technology drive their life.
      </div>
      <div
        class="text-sm w-80 m-auto text-center leading-relaxed py-5 laptop:text-left laptop:ml-0"
      >
        Gone are the days of writing quotes on paper and sending snail mail.
        Using technology like email, text messaging, mobile payments, and GPS,
        we can provide a streamlined customer experience. Join Now and close
        more deals!
      </div>
      <div class="flex justify-center laptop:justify-start">
        <div class="mr-2">
          <img
            class="download-img laptop:w-download-img-desktop"
            :src="require('@/assets/home/app_store_icon.png')"
          />
        </div>
        <div>
          <img
            class="download-img laptop:w-download-img-desktop"
            :src="require('@/assets/home/google_play_icon.png')"
          />
        </div>
      </div>
    </div>
    <div class="pt-14 laptop:w-6/12 laptop:pt-10">
      <img
        class="m-auto phone-1-container laptop:w-phonelg-1-container laptop:mr-0"
        :src="require('@/assets/home/phone_1.png')"
      />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.bg-hero {
  background-image: url(../../assets/home/hero_banner_bg.png);
}
</style>
