<template>
  <div class="four-o-four flex h-screen overflow-y-scroll">
    <Sidebar :user="user" />
    <div class="flex-grow four-o-four-content">
      <div class="bg-blue-500 flex py-4 px-10 h-10"></div>
      <div class="four-o-four p-8">
        <div class="mx-auto max-w-5xl space-y-6">
          <h1 class="text-5xl font-bold">
            404 Error
          </h1>
          <p class="text-xl font-medium">
            You've landed on a location that doesn't seem to exist.
          </p>
          <p class="text-xl font-medium">
            If you think this is an error on our part, please contact support at
            <a
              class="text-blue-500 hover:text-blue-900"
              href="mailto:support@quoteworx.com&subject=404 Error"
              >support@quoteworx.com</a
            >
          </p>
          <div><img :src="require('@/assets/404.png')" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";

export default {
  components: {
    Sidebar,
  },
  props: ["user"],
  setup() {
    // setup
  },
};
</script>

<style scoped>
.four-o-four-content {
  height: 1000px;
}
</style>
