<template>
  <div class="jobs flex h-screen overflow-y-scroll">
    <Sidebar :user="user" />
    <div class="flex-grow">
      <div class="bg-blue-500 flex py-4 px-10 h-10"></div>
      <router-view :user="user" @loadModalImage="loadModalImage" />
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";

export default {
  components: {
    Sidebar,
  },
  props: ["user"],
  emits: ["loadModalImage"],
  setup(props, context) {
    const loadModalImage = (data) => {
      context.emit("loadModalImage", data);
    };

    return {
      loadModalImage,
    };
  },
};
</script>
