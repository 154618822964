<template>
  <div class="bg-gray-800 py-20">
    <div
      v-show="false"
      class="w-full text-white text-2xl text-center pb-5 laptop:text-4xl"
    >
      SUBSCRIBE TO OUR NEWSLETTER
    </div>
    <div
      v-show="false"
      class="mx-auto my-5 max-w-4xl text-lg flex rounded  text-white"
    >
      <div
        class="flex flex-grow rounded-l border-l border-t border-b border-gray-500"
      >
        <input
          class="w-full rounded-l pl-4 bg-gray-800"
          placeholder="Email Address:"
          v-model="email"
          autocomplete="email"
        />
      </div>
      <button
        @click.prevent="submitNewsletter"
        class="px-10 py-3 rounded-r bg-orange-600 font-semibold"
      >
        SUBMIT
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    const email = ref();

    const submitNewsletter = async () => {
      // console.log("newsletter submitted", email.value);
    };

    return { email, submitNewsletter };
  },
};
</script>

<style></style>
