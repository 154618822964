<template>
  <div
    class="flex items-center justify-center bg-gray-50 py-12 px-4 tablet:px-6 laptop:px-8"
  >
    <div class="max-w-md w-full space-y-4">
      <form class="space-y-6" @submit.prevent="handleForgot">
        <div class="rounded shadow-sm -space-y-px">
          <div>
            <label for="email" class="sr-only">Email address</label>
            <input
              id="email"
              v-model="email"
              type="email"
              autocomplete="email"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-200 placeholder-gray-500 text-gray-900 rounded-t focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10"
              placeholder="Email address"
            />
          </div>
        </div>

        <div class="space-y-3">
          <button
            type="submit"
            class="relative bg-blue-600 text-white text-lg font-medium w-full rounded py-2 flex justify-center gap-2"
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: solid/lock-closed -->
              <svg
                class="h-5 w-5 text-blue-500 group-hover:text-blue-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            <div v-if="isPending" class="spinner"></div>
            Reset Password
          </button>
          <router-link
            to="login"
            class="block text-gray-400 hover:text-blue-500"
          >
            Return to Login
          </router-link>
        </div>
      </form>

      <div
        v-if="error || result"
        :class="{ 'text-red-600': error }"
        class="text-center font-semibold"
      >
        {{ error || result }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import usePasswordReset from "@/composables/usePasswordReset";

export default {
  setup() {
    const isPending = ref(false);
    const router = useRouter();
    const { error, reset } = usePasswordReset();
    const email = ref();
    const result = ref();

    const handleForgot = async () => {
      isPending.value = true;
      await reset(email.value);
      isPending.value = false;
      if (!error.value) {
        result.value = "Password reset email has been sent.";
        setTimeout(() => router.push({ name: "Login" }), 6000);
      }
    };

    return { isPending, email, error, result, handleForgot };
  },
};
</script>
