import { ref, watchEffect } from 'vue'
import { db } from '@/firebaseConfig'

export default function getCollection(collection) {
  const documents = ref(null)
  const error = ref(null)

  let collectionRef = db.collection(collection)
    .orderBy('createdAt')

  const unsub = collectionRef.onSnapshot(snap => {
    let results = []
    snap.docs.forEach(doc => {
      // checking for createdAt ensures the data has been written to the server
      doc.data().createdAt && results.push({ ...doc.data(), id: doc.id })
    })
    documents.value = results
    error.value = null
  }, (err) => {
    console.error(err.message)
    documents.value = null
    error.value = 'Could not fetch data'
  })

  watchEffect((onInvalidate) => {
    // unsub when component is unmounted
    onInvalidate(() => unsub())
  })

  return { error, documents }
}
