import { ref } from 'vue'
import { auth } from '@/firebaseConfig'

export default function useLogout() {
  const error = ref(null)

  const logout = async () => {
    error.value = null
    try {
      await auth.signOut()
    } catch (err) {
      console.error(err.message)
      error.value = err.message
    }
  }

  return { error, logout }
}
