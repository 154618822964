<template>
  <div
    class="w-56 min-w-56 sidebar sticky top-0 border-solid border-gray-200 border-r"
  >
    <div class="p-6 space-y-6">
      <router-link to="/"
        ><img class="sidebar-logo" src="@/assets/quoteworx-logo-75.png"
      /></router-link>
      <div class="">
        <div class="pl-2.5">
          <div class="font-bold">
            {{ user?.displayName }}
          </div>
          <div class="text-xs capitalize">
            {{ user?.claims?.user_role }}
          </div>
        </div>
      </div>
      <div class="pl-2.5">
        <ul class="space-y-4">
          <li class="font-medium uppercase">
            <router-link to="/dashboard">Dashboard</router-link>
          </li>
          <li
            v-if="
              user?.claims?.user_role == 'superadmin' ||
                user?.claims?.user_role == 'masquerade'
            "
            class="font-medium uppercase"
          >
            <router-link to="/businesses">Businesses</router-link>
          </li>
          <li
            v-if="
              user?.claims?.user_role == 'admin' ||
                user?.claims?.user_role == 'office' ||
                user?.claims?.user_role == 'masquerade'
            "
            class="font-medium uppercase"
          >
            <router-link to="/business/settings">Settings</router-link>
          </li>
          <li
            v-if="
              user?.claims?.user_role == 'admin' ||
                user?.claims?.user_role == 'office' ||
                user?.claims?.user_role == 'masquerade'
            "
            class="font-medium uppercase"
          >
            <router-link to="/search">Search</router-link>
          </li>
          <li
            v-if="
              user?.claims?.user_role == 'admin' ||
                user?.claims?.user_role == 'office' ||
                user?.claims?.user_role == 'masquerade'
            "
            class="font-medium uppercase"
          >
            <router-link to="/customers">Customers</router-link>
          </li>
          <li
            v-if="
              user?.claims?.user_role == 'admin' ||
                user?.claims?.user_role == 'office' ||
                user?.claims?.user_role == 'masquerade'
            "
            class="font-medium uppercase"
          >
            <router-link to="/locations">Locations</router-link>
          </li>
          <li
            v-if="
              user?.claims?.user_role == 'admin' ||
                user?.claims?.user_role == 'office' ||
                user?.claims.user_role == 'estimator' ||
                user?.claims?.user_role == 'masquerade'
            "
            class="font-medium uppercase"
          >
            <router-link to="/quotes">Quotes</router-link>
          </li>
          <li
            v-if="
              user?.claims?.user_role == 'admin' ||
                user?.claims?.user_role == 'office' ||
                user?.claims?.user_role == 'estimator' ||
                user?.claims?.user_role == 'masquerade'
            "
            class="font-medium uppercase"
          ></li>
          <li
            class="font-medium uppercase"
            v-if="
              user?.claims?.user_role == 'admin' ||
                user?.claims?.user_role == 'office' ||
                user?.claims?.user_role == 'masquerade'
            "
          >
            <router-link to="/jobs">Jobs</router-link>
          </li>
          <li class="font-medium uppercase">
            <router-link to="/logout">Logout</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user"],
  setup() {
    // setup
  },
};
</script>

<style scoped>
.sidebar {
  height: 100%;
}
.sidebar-logo {
  width: 200px;
}
</style>
