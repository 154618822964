import { ref } from 'vue'
import { auth } from '@/firebaseConfig'

export default function getUser() {
  const user = ref(auth.currentUser)

  auth.onAuthStateChanged(async _user => {
    user.value = _user
    if (user.value) {
      await auth.currentUser.getIdTokenResult().then(token => {
        user.value.claims = token.claims
      })
    }
    // console.log('user', user.value)
  })

  return { user }
}
