<template>
  <div
    :class="{ hidden: !showMapModal }"
    @click.self="showMapModal = !showMapModal"
    class="absolute z-10 p-6 w-full h-full bg-black bg-opacity-80 flex justify-center items-center"
  >
    <div ref="modal" class="p-6 pt-4 bg-white w-min h-min rounded relative">
      <svg
        @click="showMapModal = !showMapModal"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="w-7 h-7 text-black bg-white rounded-full cursor-pointer absolute -top-2 -right-2"
      >
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
          clip-rule="evenodd"
        />
      </svg>
      <p v-if="modalMap" class="pb-2 w-max font-semibold">{{ modalMap }}</p>
      <div class="map" ref="mapDiv"></div>
    </div>
  </div>
  <div
    :class="{ hidden: !showImageModal }"
    @click.self="showImageModal = !showImageModal"
    class="absolute z-10 p-6 w-full h-full bg-black bg-opacity-80 flex justify-center items-center"
  >
    <div ref="modal" class="p-4 bg-white w-min h-min rounded relative">
      <svg
        @click="showImageModal = !showImageModal"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="w-7 h-7 text-black bg-white rounded-full cursor-pointer absolute -top-2 -right-2"
      >
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
          clip-rule="evenodd"
        />
      </svg>
      <img
        v-if="modalImage"
        :src="modalImage"
        class="max-h-modal max-w-modal"
      />
    </div>
  </div>
  <router-view
    :user="user"
    @loadModalMap="loadModalMap"
    @loadModalImage="loadModalImage"
  />
</template>

<script>
import { ref } from "vue";
import getUser from "@/composables/getUser";
import useDocument from "@/composables/useDocument";

export default {
  setup() {
    const { user } = getUser();
    const showMapModal = ref(false);
    const modalMap = ref();
    const showImageModal = ref(false);
    const modalImage = ref();
    const map = ref(null);
    const mapDiv = ref(null);

    const loadModalMap = (data) => {
      showMapModal.value = true;
      modalMap.value = `Location: ${data.address}`;

      // init google maps
      map.value = new window.google.maps.Map(mapDiv.value, {
        mapTypeId: "roadmap",
        mapTypeControl: false,
        zoom: 15,
        streetViewControl: false,
        center: { lat: 45.177, lng: -83.459 },
      });
      // add location marker
      new window.google.maps.Geocoder().geocode(
        { address: data.address },
        (result, status) => {
          if (status === "OK") {
            const marker = new window.google.maps.Marker({
              map: map.value,
              position: result[0].geometry.location,
            });
            // re-center
            if (data.location_type != "ROOFTOP") {
              const { updateDoc: updateDoc } = useDocument(
                `businesses/${data.business_id}/locations`,
                data.location_id
              );

              updateDoc({
                location_type: result[0].geometry.location_type,
              });
            }
            map.value.setCenter(marker.getPosition());
          }
        }
      );
    };

    const loadModalImage = (data) => {
      showImageModal.value = true;
      modalImage.value = data;
    };

    const onEscape = (e) => {
      if (showMapModal.value && e.keyCode === 27) {
        showMapModal.value = false;
      }
      if (showImageModal.value && e.keyCode === 27) {
        showImageModal.value = false;
      }
    };

    document.addEventListener("keydown", onEscape);

    return {
      user,
      showMapModal,
      modalMap,
      showImageModal,
      modalImage,
      loadModalMap,
      loadModalImage,
      mapDiv,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.map {
  width: 90vw;
  height: 80vh;
  max-width: 800px;
  max-height: 600px;
}
input:disabled {
  background: #e5e5e5;
}
.attachment-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
}
.spinner {
  height: 25px;
  width: 25px;
  background-color: transparent;
  border-top: 5px solid white;
  border-right: 5px solid white;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  border-radius: 50%;
  animation: spin-around 1s infinite linear;
}
.spinner.dark {
  border-top: 5px solid #111827;
  border-right: 5px solid #111827;
}
@keyframes spin-around {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.hover-trigger .hover-target {
  display: none;
}
.hover-trigger:hover .hover-target {
  display: inline-block;
}
</style>
