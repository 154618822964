<template>
  <div class="py-20 bg-orange-600 text-white">
    <div
      class="text-center font-semibold text-2xl tablet:text-3xl m-auto laptop:w-full"
    >
      Our number 1 goal is to make <br class="block tablet:hidden" />software
      that's <br class="hidden tablet:block laptop:hidden" />simple and gets the
      <br class="block tablet:hidden laptop:block" />
      intended task completed <br class="hidden tablet:block laptop:hidden" />in
      the <br class="block tablet:hidden" />most professional way possible
    </div>
  </div>
</template>
