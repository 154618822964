<template>
  <div class="dashboard flex h-screen overflow-y-scroll">
    <Sidebar :user="user" />
    <div class="flex-grow dashboard-content">
      <div class="bg-blue-500 flex py-4 px-10 h-10"></div>
      <div class="dashboard p-8">
        <div class="mx-auto max-w-5xl space-y-6">
          <h1 class="text-3xl font-semibold">
            Dashboard
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";

export default {
  components: {
    Sidebar,
  },
  props: ["user"],
  setup() {
    // setup
  },
};
</script>

<style scoped>
.dashboard-content {
  height: 1000px;
}
</style>
