<template>
  <div
    class="p-40 bg-contact bg-bottom bg-no-repeat bg-cover laptop:pt-0 space-y-5"
  >
    <div
      class="text-xl font-semibold w-96 m-auto text-center laptop:text-2xl laptop:w-full"
    >
      We are currently looking for businesses to help us Beta test the software.
    </div>
    <div class="text-md w-96 m-auto text-center laptop:text-xl laptop:w-full">
      Being a Beta Tester will come <br class="laptop:hidden" />
      with many benefits down the road.<br />
    </div>
    <div>
      <div class="text-md text-center font-medium laptop:text-2xl">
        If you're interested, please
      </div>
      <div class="text-2xl font-semibold text-center laptop:text-4xl">
        Fill Out The Form Below
      </div>
      <div class="text-md text-center font-medium laptop:text-2xl">
        And We Will Contact You ASAP!
      </div>
    </div>
    <form
      @submit.prevent="submitContact"
      class="mx-auto mb-5 px-10 space-y-5 max-w-4xl text-lg"
    >
      <input
        @change="validateName()"
        class="w-full border rounded px-4 py-2"
        v-model="contact.name"
        required
        placeholder="Name:"
        autocomplete="name"
      />
      <input
        type="email"
        class="w-full border rounded px-4 py-2 border-gray-200 text-lg h-11.5"
        :class="{ 'border-red-500': !validateEmail() }"
        v-model="contact.email"
        required
        placeholder="Email:"
        autocomplete="email"
      />
      <input
        @change="contact.phone = formatPhone(contact.phone)"
        class="w-full border rounded px-4 py-2 border-gray-200 text-lg h-11.5"
        :class="{ 'border-red-500': !validatePhone() }"
        v-model="contact.phone"
        type="tel"
        pattern="[+1(\s]*[2-9]{1}[0-9]{2}[-.)\s]*[0-9]{3}[-.\s]*[0-9]{4}"
        name="phone"
        placeholder="Phone:"
        autocomplete="tel"
      />
      <input
        @change="validateURL()"
        class="w-full border rounded px-4 py-2 border-gray-200"
        :class="{ 'border-red-500': !validateURL() }"
        v-model="contact.website"
        placeholder="Website:"
        autocomplete="url"
      />

      <div class="pt-2 flex justify-center">
        <button
          :class="{
            'bg-gray-300': !(
              validateName() &&
              validateEmail() &&
              validatePhone() &&
              validateURL()
            ),
            'bg-orange-500':
              validateName() &&
              validateEmail() &&
              validatePhone() &&
              validateURL(),
          }"
          class="submit-btn text-white text-2xl font-bold w-60 rounded py-2 flex justify-center gap-2"
          :disabled="
            !validateName() ||
              !validateEmail() ||
              !validatePhone() ||
              !validateURL()
          "
        >
          <div v-if="isPending" class="spinner"></div>
          Send
        </button>
      </div>

      <div
        :class="{ 'text-red-600': formMessageError }"
        class="text-center font-semibold"
      >
        {{ formMessage }}
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";
import { functions } from "@/firebaseConfig";
import usePhone from "@/composables/usePhone";

export default {
  setup() {
    const isPending = ref(false);
    const formMessage = ref();
    const formMessageError = ref(false);
    const contact = ref({});

    const { formatPhone } = usePhone();

    const validateName = () => {
      if (contact.value?.name) {
        contact.value.name = contact.value.name
          .replace(/<style[^>]*>.*<\/style>/gm, "")
          // Remove script tags and content
          .replace(/<script[^>]*>.*<\/script>/gm, "")
          // Remove all opening, closing and orphan HTML tags
          .replace(/<[^>]+>/gm, "")
          // Remove leading spaces and repeated CR/LF
          .replace(/([\r\n]+ +)+/gm, "");
      }
      return true;
    };

    const validateEmail = () => {
      if (contact.value?.email) {
        return isValidEmail(contact.value?.email);
      }
      return true;
    };

    const isValidEmail = (string) => {
      var res = string.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
      );
      return res !== null;
    };

    const validatePhone = () => {
      if (contact.value?.phone) {
        return contact.value.phone.length == 14;
      }
      return true;
    };

    const validateURL = () => {
      if (contact.value?.website) {
        return isValidURL(contact.value?.website);
      }
      return true;
    };

    const isValidURL = (string) => {
      var res = string.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
      );
      return res !== null;
    };

    const submitContact = async () => {
      isPending.value = true;
      console.log("from", process.env.VUE_APP_CONTACT_FROM_EMAIL);
      console.log("to", process.env.VUE_APP_CONTACT_TO_EMAIL);
      console.log(
        "body",
        `name: ${contact.value.name}\nemail: ${contact.value.email}\nphone: ${contact.value.phone}\nwebsite: ${contact.value.website}`
      );
      try {
        const sendEmail = functions.httpsCallable("send_contact_form");
        const data = {
          from: `${process.env.VUE_APP_CONTACT_FROM_EMAIL}`,
          to: `${process.env.VUE_APP_CONTACT_TO_EMAIL}`,
          body: `name: ${contact.value.name}\nemail: ${contact.value.email}\nphone: ${contact.value.phone}\nwebsite: ${contact.value.website}`,
        };

        await sendEmail(data);

        isPending.value = false;
        formMessageError.value = false;
        formMessage.value = "Message Sent!";
        contact.value = {};
        setTimeout(() => (formMessage.value = ""), 6000);
      } catch (err) {
        isPending.value = false;
        formMessageError.value = true;
        formMessage.value = err;
      }
    };

    return {
      isPending,
      formMessage,
      formMessageError,
      contact,
      formatPhone,
      validateName,
      validatePhone,
      validateEmail,
      validateURL,
      submitContact,
    };
  },
};
</script>

<style scoped>
.bg-contact {
  background-image: url(../../assets/home/contact_bg_mobile.png);
}
@media (min-width: 1040px) {
  .bg-contact {
    background-image: url(../../assets/home/contact_bg_desktop.png);
  }
}
</style>
