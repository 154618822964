<template>
  <div
    class="flex items-center justify-center bg-gray-50 py-12 px-4 tablet:px-6 laptop:px-8"
  >
    <div class="max-w-md w-full space-y-4">
      <form class="space-y-6" @submit.prevent="handleLogin">
        <div class="rounded shadow-sm -space-y-px">
          <div>
            <label for="email" class="sr-only">Email address</label>
            <input
              id="email"
              v-model="email"
              type="email"
              autocomplete="email"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-200 placeholder-gray-500 text-gray-900 rounded-t focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10"
              placeholder="Email address"
            />
          </div>
          <div class="relative">
            <label for="password" class="sr-only">Password</label>
            <input
              id="password"
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              autocomplete="current-password"
              required
              class="appearance-none rounded-none relative block w-full pl-3 pr-10   py-2 border border-gray-200 placeholder-gray-500 text-gray-900 rounded-b focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10"
              placeholder="Password"
            />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              @click="showPassword = !showPassword"
              v-if="!showPassword"
              class="h-6 w-6 absolute top-6 right-2 cursor-pointer z-10 -mt-4 text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 absolute top-6 right-2 cursor-pointer z-10 -mt-4 text-gray-500"
              fill="none"
              v-if="showPassword"
              @click="showPassword = !showPassword"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
              />
            </svg>
          </div>
        </div>

        <div class="space-y-3">
          <button
            type="submit"
            class="relative bg-blue-600 text-white text-lg font-medium w-full rounded py-2 flex justify-center gap-2"
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: solid/lock-closed -->
              <svg
                class="h-5 w-5 text-blue-500 group-hover:text-blue-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            <div v-if="isPending" class="spinner"></div>
            Sign in
          </button>
          <router-link
            to="forgot-password"
            class="block text-gray-400 hover:text-blue-500"
          >
            Forgot Password?
          </router-link>
        </div>
      </form>

      <div v-if="error" class="text-center text-red-600 font-semibold">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import useLogin from "@/composables/useLogin";

export default {
  setup(props, context) {
    const isPending = ref(false);
    const email = ref("");
    const password = ref("");
    const { error, login } = useLogin();
    const showPassword = ref(false);

    const handleLogin = async () => {
      isPending.value = true;
      await login(email.value, password.value);
      isPending.value = false;
      if (!error.value) {
        // console.log("user logged in");
        context.emit("login");
      }
    };

    return { email, password, error, handleLogin, isPending, showPassword };
  },
};
</script>
