import { createApp } from 'vue'
import { auth } from './firebaseConfig'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'

let app
const googlemapsKey = process.env.VUE_APP_FIREBASE_API_KEY;
const script = document.createElement("script");
script.setAttribute("src", `https://maps.google.com/maps/api/js?key=${googlemapsKey}&libraries=places`);
script.setAttribute("async", "");
document.head.appendChild(script);

auth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App)
      .use(router)
      .mount('#app')
  }
})
