<template>
  <div class="fixed w-full bg-white h-14"></div>
  <nav
    class="fixed block tablet:flex justify-between bg-black bg-opacity-90 font-semibold text-white w-full py-3 px-6 tablet:px-10 min-h-14"
  >
    <router-link to="/" class="text-xl">QuoteWorx</router-link>
    <div v-if="user" class="text-sm my-auto mt-2">
      <span v-if="user?.displayName">
        Hi, {{ user.displayName }}
        <span class="px-2">|</span>
      </span>
      <router-link to="/dashboard" class="hover:text-gray-200"
        >Dashboard</router-link
      >
      <span class="px-2">|</span>
      <a href="" @click.prevent="handleLogout" class="hover:text-gray-200"
        >Logout</a
      >
    </div>
    <div v-else class="text-sm my-auto mt-2">
      <router-link to="/login" class="hover:text-gray-200">Login</router-link>
    </div>
  </nav>
  <div class="mt-14 w-full"></div>
</template>

<script>
import useLogout from "@/composables/useLogout";
export default {
  props: ["user"],
  setup() {
    const { error, logout } = useLogout();

    const handleLogout = async () => {
      await logout();
      if (!error.value) {
        // console.log("user logged out");
      }
    };

    return { error, handleLogout };
  },
};
</script>
