<template>
  <div
    class="flex items-center justify-center bg-gray-50 py-12 px-4 tablet:px-6 laptop:px-8"
  >
    <div class="max-w-md w-full space-y-6">
      <form @submit.prevent="handleLogout">
        <button
          type="submit"
          class="relative bg-blue-600 text-white text-lg font-medium w-full rounded py-2 flex justify-center gap-2"
        >
          <div v-if="isPending" class="spinner"></div>
          Log out
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import useLogout from "@/composables/useLogout";

export default {
  setup() {
    const isPending = ref(false);
    const router = useRouter();
    const { error, logout } = useLogout();

    const handleLogout = async () => {
      isPending.value = true;
      await logout();
      isPending.value = false;
      if (!error.value) {
        router.push({ name: "Login" });
      }
    };

    return { error, handleLogout, isPending };
  },
};
</script>
