<template>
  <div class="text-center py-40">
    <div class="text-4xl font-semibold">Our Pricing</div>
    <div class="coming-soon uppercase font-extrabold text-orange-600">
      COMING SOON
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.coming-soon {
  font-size: 50px;
}
@media (min-width: 1040px) {
  .coming-soon {
    font-size: 69px;
  }
}
</style>
