<template>
  <div
    class="bg-features bg-bottom bg-no-repeat bg-cover laptop:bg-center text-white"
  >
    <div
      class="w-80 laptop:w-full text-center font-semibold text-2xl m-auto pt-14 pb-5"
    >
      <div>
        Using the Latest <br class="laptop:hidden" />
        Technology, <br class="hidden laptop:block" />
        We Can Offer Amazing Features!
      </div>
    </div>
    <div class="flex justify-center">
      <div class="hidden laptop:block text-left mt-10">
        <div class="flex justify-end font-bold">
          <div class="text-right mr-7 mb-10">
            Customer Relationship <br />
            Management
          </div>
          <div>
            <img
              :src="
                require('@/assets/home/icons-white/customer_relationship.png')
              "
            />
          </div>
        </div>
        <div class="flex justify-end font-bold">
          <div class="text-right mr-7 mb-10">
            Send Quotes from <br />
            IOS or Android
          </div>
          <div>
            <img :src="require('@/assets/home/icons-white/send_quotes.png')" />
          </div>
        </div>
        <div class="flex justify-end font-bold">
          <div class="text-right mr-7 mb-10">
            Simple Quote <br />
            Approval Process
          </div>
          <div>
            <img
              :src="require('@/assets/home/icons-white/quote_approval.png')"
            />
          </div>
        </div>
        <div class="flex justify-end font-bold mb-10">
          <div class="text-right mr-7">
            Easy Google <br />
            Review Collection
          </div>
          <div>
            <img
              :src="require('@/assets/home/icons-white/google_review.png')"
            />
          </div>
        </div>
      </div>
      <div class="px-5">
        <img class="w-80 m-auto" :src="require('@/assets/home/phone_2.png')" />
      </div>
      <div class="hidden laptop:block mt-10">
        <div class="flex font-bold mb-10">
          <div>
            <img :src="require('@/assets/home/icons-white/estimator.png')" />
          </div>
          <div class="ml-7">
            Estimator <br />
            Assignments
          </div>
        </div>
        <div class="flex font-bold mb-10">
          <div>
            <img :src="require('@/assets/home/icons-white/scheduling.png')" />
          </div>
          <div class="ml-7">
            Daily Work Order <br />
            Scheduling
          </div>
        </div>
        <div class="flex font-bold mb-10">
          <div>
            <img :src="require('@/assets/home/icons-white/track_crew.png')" />
          </div>
          <div class="ml-7">
            Track Crew Progress <br />
            in Real-Time
          </div>
        </div>
        <div class="flex font-bold mb-10">
          <div>
            <img :src="require('@/assets/home/icons-white/quickbooks.png')" />
          </div>
          <div class="ml-7">
            Quickbooks <br />
            Online Integration
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex justify-center text-base pt-40 pb-20 laptop:pt-20">
    <div class="laptop:hidden">
      <div class="flex items-center pb-4">
        <div class="pr-6">
          <img
            :src="
              require('@/assets/home/icons-black/customer_relationship.png')
            "
          />
        </div>
        <div>
          Customer Relationship <br />
          Management
        </div>
      </div>
      <div class="flex items-center pb-4">
        <div class="pr-6">
          <img :src="require('@/assets/home/icons-black/send_quotes.png')" />
        </div>
        <div>
          Send Quotes from <br />
          IOS or Android
        </div>
      </div>
      <div class="flex items-center pb-4">
        <div class="pr-6">
          <img :src="require('@/assets/home/icons-black/quote_approval.png')" />
        </div>
        <div>
          Simple Quote <br />
          Approval Process
        </div>
      </div>
      <div class="flex items-center pb-4">
        <div class="pr-6">
          <img :src="require('@/assets/home/icons-black/google_review.png')" />
        </div>
        <div class="w-64">
          Easy Google <br />
          Review Collection
        </div>
      </div>
      <div class="flex items-center pb-4">
        <div class="pr-6">
          <img :src="require('@/assets/home/icons-black/estimator.png')" />
        </div>
        <div>
          Estimator <br />
          Assignments
        </div>
      </div>
      <div class="flex items-center pb-4">
        <div class="pr-6">
          <img :src="require('@/assets/home/icons-black/scheduling.png')" />
        </div>
        <div>
          Daily Work Order <br />
          Scheduling
        </div>
      </div>
      <div class="flex items-center pb-4">
        <div class="pr-6">
          <img :src="require('@/assets/home/icons-black/track_crew.png')" />
        </div>
        <div>
          Track Crew Progress <br />
          in Real-Time
        </div>
      </div>
      <div class="flex items-center pb-4">
        <div class="pr-6">
          <img :src="require('@/assets/home/icons-black/quickbooks.png')" />
        </div>
        <div>
          Quickbooks <br />
          Online Integration
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.bg-features {
  height: 620px;
  background-image: url(../../assets/home/features_bg_mobile.png);
}
@media (min-width: 1040px) {
  .bg-features {
    background-image: url(../../assets/home/features_bg_desktop.png);
  }
}
</style>
