<template>
  <div class="settings flex h-screen overflow-y-scroll">
    <Sidebar :user="user" />
    <div class="flex-grow">
      <div class="bg-blue-500 py-4 px-10 h-10"></div>
      <router-view :user="user" />
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";

export default {
  components: {
    Sidebar,
  },
  props: ["user"],
  setup() {
    // setup
  },
};
</script>
