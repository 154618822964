<template>
  <div class="customers flex h-screen overflow-y-scroll">
    <Sidebar :user="user" />
    <div class="flex-grow">
      <div class="bg-blue-500 flex py-4 px-10 h-10"></div>
      <router-view :user="user" @loadModalMap="loadModalMap" />
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";

export default {
  components: {
    Sidebar,
  },
  props: ["user"],
  emits: ["loadModalMap"],
  setup(props, context) {
    const loadModalMap = (data) => {
      context.emit("loadModalMap", data);
    };

    return {
      loadModalMap,
    };
  },
};
</script>
