<template>
  <div class="home flex flex-col">
    <Navbar :user="user" />
    <Hero />
    <Features />
    <Contact />
    <Stats />
    <Pricing />
    <Newsletter />
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/home/Navbar";
import Hero from "@/components/home/Hero.vue";
import Features from "@/components/home/Features.vue";
import Contact from "@/components/home/Contact.vue";
import Stats from "@/components/home/Stats.vue";
import Pricing from "@/components/home/Pricing.vue";
import Newsletter from "@/components/home/Newsletter.vue";
import Footer from "@/components/home/Footer.vue";

export default {
  components: {
    Navbar,
    Hero,
    Features,
    Contact,
    Stats,
    Pricing,
    Newsletter,
    Footer,
  },
  props: ["user"],
};
</script>
